import Link from 'next/link';

import { Grid, colors, Typography, Box, Button } from '@material-ui/core';
import AutoFixHighIcon from '@material-ui/icons/AutoFixHigh';

import PropTypes from 'prop-types';

import { SectionHeader, IconAlternate } from '@/src/components/molecules';
import { CardBase, DescriptionListIcon } from '@/src/components/organisms';

export default function Features(props) {
  const { className, ...rest } = props;

  const features = [
    {
      icon: 'fas fa-shopping-cart',
      color: 'amber',
      title: 'Eskolare Commerce',
      subtitle:
        'Trabalhe com múltiplos canais de venda, cada canal com suas próprias regras comerciais',
      items: [
        'Faça a venda dos seus produtos ou serviços e, tenha toda a gestão de pedidos,estoque, fluxo financeiro de todas as suas lojas em um só portal. Faça reembolsos totais ou parciais das suas vendas.',
      ],
    },
    {
      icon: 'fas fa-money-check-alt',
      color: 'lightGreen',
      title: 'Eskolare Pay',
      subtitle: 'Tenha o sistema de pagamento integrado à sua loja',
      items: [
        'Escolha o método de pagamento que você preferir, a Eskolare Pay conta com cartão de crédito, boleto, Pix, dois cartões, cartão + boleto, cartão + pix e até boleto parcelado.',
        'Venda sua solução com recorrência que não ocupa o limite de crédito ou como um pedido único.',
      ],
      href: '/solutions/eskolare-pay',
    },
    {
      icon: 'fas fa-divide',
      color: 'deepPurple',
      title: 'Eskolare Split',
      subtitle: 'Comissione automaticamente seus parceiros',
      items: [
        'A solução permite dividir os recebimentos entre seus parceiros, você decide os valores e todos os parceiros recebem de forma transparente. Seus parceiros acompanham as vendas e suas comissões de forma online e transparente.',
      ],
    },
    {
      icon: 'fas fa-link',
      color: 'brown',
      title: 'Eskolare Links de pagamento',
      subtitle: 'Crie links de pagamento específicos para cada usuário com preços customizados',
      items: [
        'Suas famílias querem receber o link de pagamento diretamente em seus carrinhos e só fazer o pagamento? Isso é possível com a nossa solução.',
      ],
    },
    {
      icon: 'fas fa-eye',
      color: 'cyan',
      title: 'Eskolare Insights',
      subtitle: 'Com análise de dados, venda mais e melhor com o suporte de um gestor de negócios',
      items: [
        'Melhore a performance de vendas da sua loja com este módulo. Acompanhe os carrinhos abandonados, extraia relatórios customizados de performance de vendas, entenda o comportamento de compra das suas famílias e analise a penetração de vendas serie por serie.',
      ],
    },
    {
      icon: 'fas fa-box',
      color: 'blue',
      title: 'Eskolare Catálogo',
      subtitle: 'Faça toda configuração e personalização do seu produto',
      items: [
        'Crie produtos e serviços de forma totalmente customizada. Anexe termos de compromisso nas suas vendas com validade jurídica, crie limites de quantidade de compras para usuários, defina descontos e cupons por produto ou canal de venda, crie kits de produtos e muito mais...',
      ],
    },
    {
      icon: 'fas fa-headset',
      color: 'pink',
      title: 'Eskolare Desk',
      subtitle: 'Seus clientes atendidos de forma centralizada',
      items: [
        'Tenha uma estrutura de atendimento integrada a sua plataforma de vendas. Você se comunica com seus clientes pela plataforma e acompanha os indicadores de satisfação, tempo de resposta, quantidade de chamados entre outros.',
      ],
    },
    {
      icon: 'fas fa-plug',
      color: 'orange',
      title: 'Eskolare Integração',
      subtitle: 'Integre com seu Gateway e seu ERP Educacional',
      items: [
        'Integre seu módulo de vendas ao seu próprio Gateway de pagamento, utilizando as suas taxas já negociadas.',
        'Também temos integração com ERPs Educacionais de mercado.',
        'Emita notas fiscais de serviços automaticamente pela plataforma.',
      ],
    },
    {
      icon: 'fas fa-shipping-fast',
      color: 'blueGrey',
      title: 'Eskolare Logística',
      subtitle: 'Gerencie e simplifique a gestão de entrega para suas escolas e famílias',
      items: [
        'Temos parceiros integrados ao módulo de vendas da Eskolare que fazem a logística, separação e envio do seu material. Conte com especialistas para gerenciar seus negócios de ponta a ponta.',
        'Crie formas de entrega diferentes para cada canal de vendas, defina tabelas de frete diferenciadas e, com o módulo entrega na instituição, a escola sabe quais foram as famílias que realizaram o pagamento e, desta forma entrega corretamente os materiais para as famílias.',
      ],
    },
    {
      icon: 'fas fa-receipt',
      color: 'teal',
      title: 'Eskolare Nota Fiscal',
      subtitle: 'Emita automaticamente as notas fiscais de produtos ou de serviços',
      items: [
        'Ao vender qualquer solução dentro da Eskolare você não precisa se preocupar mais em emitir a nota fiscal por meio de outra solução, no próprio acesso da Eskolare você pode emitir suas notas fiscais uma a uma ou também em lote.',
        'As famílias recebem o e-mail com a nota anexada e seu operacional ganha muita eficiência.',
      ],
    },
    {
      icon: 'fas fa-handshake',
      color: 'lightBlue',
      title: 'Acordo de Vendas',
      subtitle: 'Acompanhe a performance do seu time comercial',
      items: [
        'Cada escola que o seu time comercial assina contrato, você cadastra a expectativa de vendas.',
        'A medida que os pedidos são realizados, você faz a gestão em tempo real de qual a performance por escola e por vendedor.',
        'Desta forma você consegue direcionar corretamente os seus esforços com as escolas que realmente estão precisando acionar as famílias de forma mais assertiva.',
      ],
    },
  ];

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title="Conheça mais!"
        subtitle="Veja abaixo algumas de nossas soluções:"
        fadeUp
      />
      {features.length > 0 && (
        <Grid container spacing={2}>
          {features.map(feature => (
            <Grid
              key={feature.title || feature.subtitle}
              item
              container
              alignItems="center"
              direction="column"
              xs={12}
              sm={6}
              md={4}
              data-aos="fade-up">
              <CardBase
                liftUp
                variant="outlined"
                style={{ borderTop: `5px solid ${colors[feature.color || 'grey'][500]}` }}>
                <DescriptionListIcon
                  icon={
                    <IconAlternate
                      fontIconClass={feature.icon}
                      color={colors[feature.color || 'grey']}
                      shape="circle"
                      size="small"
                    />
                  }
                  title={feature.title}
                  subtitle={feature.subtitle}
                  subtitleProps={{ color: 'textPrimary' }}
                />
                <Box p={2}>
                  <ul>
                    {feature.items.map(item => (
                      <li key={item}>
                        <Typography variant="body2" color="textSecondary">
                          {item}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
                {!!feature.href && (
                  <Link href={feature.href} passHref>
                    <Button variant="text" color="inherit" startIcon={<AutoFixHighIcon />}>
                      Saiba mais
                    </Button>
                  </Link>
                )}
              </CardBase>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}

Features.propTypes = {
  className: PropTypes.string,
  section: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  features: PropTypes.array,
  refElem: PropTypes.any,
};
